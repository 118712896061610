import { motion, type MotionProps } from 'framer-motion';
import Image from 'next/image';

import { ArrowIcon, LogoIcon } from '@endaoment-frontend/ui/icons';

import { GetStartedButton } from '../../components/GetStartedButton';
import heroCharacterShot from '../../images/decoration/hero-character.png';

import styles from './Hero.module.scss';

const makeDelayedAppearAnimation = (delay: number): MotionProps => {
  return {
    initial: 'hidden',
    animate: 'appeared',
    exit: 'hidden',
    variants: {
      appeared: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: 20 },
    },
    transition: {
      delay: 0.25 + delay * 0.25,
      type: 'spring',
      stiffness: 100,
      bounce: 0.5,
      ease: 'ease-out',
      duration: 1.5,
    },
  };
};

// IDs are implemented for a/b testing
export const Hero = () => {
  return (
    <section className={styles['hero']}>
      <div className={styles['hero-top']}>
        <div className={styles['hero-inner']}>
          <div className={styles['text']}>
            <motion.h1 id='hero-h1' {...makeDelayedAppearAnimation(0)}>
              <b>Charitable Giving</b> for
              <br />
              Savvy Donors
            </motion.h1>
            <motion.h4 id='hero-h4' {...makeDelayedAppearAnimation(1)}>
              Donate any asset to 1.8m nonprofits worldwide. No minimums. No hassle.
            </motion.h4>
            <motion.div id='hero-action-button' {...makeDelayedAppearAnimation(2)}>
              <GetStartedButton className={styles['action-button']} />
            </motion.div>
          </div>
          <motion.div className={styles['character']} {...makeDelayedAppearAnimation(2)}>
            <Image src={heroCharacterShot} alt='' width={400} />
          </motion.div>
        </div>
      </div>
      <motion.div className={styles['audiences']} {...makeDelayedAppearAnimation(3)}>
        <a href='#for-donors-section'>
          <span className={styles['audiences-tag']}>
            <LogoIcon width={26} color='currentColor' /> For Donors <ArrowIcon width={24} />
          </span>
          <h3 id='for-donors-hero-tagline'>Save on taxes today, give more tomorrow.</h3>
        </a>
        <a href='#for-advisors-section'>
          <span className={styles['audiences-tag']}>
            <LogoIcon width={26} color='currentColor' /> For Financial Advisors <ArrowIcon width={24} />
          </span>
          <h3 id='for-financial-advisors-hero-tagline'>Engage clients and grow your practice.</h3>
        </a>
        <a href='#for-nonprofits-section'>
          <span className={styles['audiences-tag']}>
            <LogoIcon width={26} color='currentColor' /> For Nonprofits <ArrowIcon width={24} />
          </span>
          <h3 id='for-nonprofits-hero-tagline'>Complex donations without the fuss.</h3>
        </a>
      </motion.div>
    </section>
  );
};
