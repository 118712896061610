import { z } from 'zod';

import { RequestHandler } from '@endaoment-frontend/data-fetching';
import type {
  AdminFund,
  CreateFundInput,
  EntityPosition,
  EntityPositionSummary,
  FundCollaborator,
  FundDetails,
  FundDistributionRecipient,
  FundDistributionRoundSummary,
  FundListing,
  FundSettingsInput,
  Letter,
  PhysicalAddress,
  UUID,
} from '@endaoment-frontend/types';
import {
  adminFundSchema,
  arraySchemaInvalidsFiltered,
  entityPositionSchema,
  entityPositionSummarySchema,
  fundCollaboratorSchema,
  fundDetailsSchema,
  fundDistributionRecipientSchema,
  fundDistributionRoundSchema,
  fundDistributionRoundSummarySchema,
  fundListingSchema,
  isUuid,
  uuidSchema,
} from '@endaoment-frontend/types';
import { arrToSorted, comparePortfoliosForSort } from '@endaoment-frontend/utils';

/**
 * Fetch the total number of community funds
 */
export const GetCommunityFundsCount = new RequestHandler(
  'GetCommunityFundsCount',
  fetch => async (): Promise<number> => {
    const res = await fetch(`/v1/funds/community/count`);
    return z.number({ coerce: true }).parse(res);
  },
);

/**
 * Fetch the total number of funds
 */
export const GetFundsCount = new RequestHandler(
  'GetFundsCount',
  fetch => async (): Promise<number> => {
    const res = await fetch(`/v1/funds/count`);
    return z.number({ coerce: true }).parse(res);
  },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/count` }),
  },
);

/**
 * Fetch an array of our most featured funds
 */
export const GetFeaturedFunds = new RequestHandler(
  'GetFeaturedFunds',
  fetch =>
    async (offset: number = 0): Promise<Array<FundListing>> => {
      // TODO: Update once backend accepts offset
      const count = 10;
      const res = await fetch('/v1/funds/featured');
      return z
        .array(fundListingSchema)
        .parse(res)
        .slice(0 * count, (offset + 1) * count);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/featured` }),
  },
);

/**
 * Fetch an array of our community featured funds
 */
export const GetCommunityFunds = new RequestHandler(
  'GetCommunityFunds',
  fetch =>
    async (count: number = 6, offset?: number): Promise<Array<FundListing>> => {
      const res = await fetch('/v1/funds/community', {
        params: {
          count,
          offset,
        },
      });
      return arraySchemaInvalidsFiltered(fundListingSchema).parse(res);
    },
);

type CreateFundBody = {
  fundInput: {
    name: string;
    description: string;
    advisor: {
      firstName: string;
      lastName: string;
      email: string;
      address: PhysicalAddress;
    };
  };
  fundSalt?: string;
  chainId: number;
  deploymentTransactionHash?: string;
  referralSource?: string;
  referralCode?: string;
};
/**
 * Mutation that needs to be called in order to create a new fund on the backend
 */
export const CreateFund = new RequestHandler(
  'CreateFund',
  fetch => async (input: CreateFundInput) => {
    const res = await fetch('/v1/funds', {
      method: 'POST',
      body: {
        fundInput: {
          name: input.fundInput.name,
          // Description is optional, but we need to send an empty string if it's not provided
          description: 'My awesome Endaoment fund!',
          advisor: input.advisor,
        },
        fundSalt: input.fundSalt ?? undefined,
        deploymentTransactionHash: input.deploymentTransactionHash ?? undefined,
        chainId: input.fundInput.chainId ?? undefined,
        referralCode: input.fundInput.referralCode ?? undefined,
        referralSource: input.fundInput.referralSource ?? undefined,
      } satisfies CreateFundBody,
    });
    return fundListingSchema.parse(res);
  },
  { isUserSpecificRequest: true, makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds` }) },
);

/**
 * Mutation that needs to be called in order to edit a fund on the backend
 */
export const EditFund = new RequestHandler(
  'EditFund',
  fetch => async (id: UUID, settings: FundSettingsInput) => {
    const res = await fetch(`/v1/funds/${id}`, {
      method: 'PUT',
      body: settings,
    });
    return fundListingSchema.parse(res);
  },
  { isUserSpecificRequest: true },
);

/**
 * Fetch a Fund using its id (primary key) or a vanityUrl
 */
export const GetFund = new RequestHandler(
  'GetFund',
  fetch =>
    async (idOrVanity: UUID | string): Promise<FundDetails> => {
      const url = isUuid(idOrVanity) ? `/v1/funds/${idOrVanity}` : `/v1/funds/vanity/${idOrVanity}`;
      const res = await fetch(url, {
        cache: 'no-cache',
      });
      return fundDetailsSchema.parse(res);
    },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/funds/:id`,
      vanity: `${baseURL}/v1/funds/vanity/:vanity`,
    }),
  },
);

/**
 * Ping for a fund's existence
 */
export const PingFund = new RequestHandler('PingFund', fetch => async (id: UUID): Promise<boolean> => {
  try {
    const res = await fetch(`/v1/funds/ping/${id}/1849fe0c-3832-4b8f-a387-553738568cd6`);
    return z.boolean({ coerce: true }).parse(res);
  } catch {
    return false;
  }
});

/**
 * Get All funds where the current user is the manager
 */
export const GetUserFunds = new RequestHandler(
  'GetUserFunds',
  fetch => async (): Promise<Array<FundListing>> => {
    const res = await fetch(`/v1/funds/mine`);
    return arraySchemaInvalidsFiltered(fundListingSchema).parse(res);
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/mine` }),
  },
);

/**
 * Get all funds where the current user is an collaborator
 */
export const GetUserCollaboratedFunds = new RequestHandler(
  'GetUserCollaboratedFunds',
  fetch => async (): Promise<Array<FundListing>> => {
    const res = await fetch(`/v1/funds/collaborating`);
    return arraySchemaInvalidsFiltered(fundListingSchema).parse(res);
  },
  {
    isUserSpecificRequest: true,
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/collaborating` }),
  },
);

/**
 * Fetch all positions associated with a Fund
 */
export const GetFundPositions = new RequestHandler(
  'GetFundPositions',
  fetch =>
    async (id: UUID, stpPricingMode: 'accurate' | 'fast'): Promise<EntityPositionSummary> => {
      const res = await fetch(`/v1/portfolios/summary/fund/${id}`, {
        query: {
          stpPricingMode,
        } satisfies { stpPricingMode: 'accurate' | 'fast' },
      });
      const parsed = entityPositionSummarySchema.parse(res);
      parsed.positions = arrToSorted(parsed.positions, (a, b) => comparePortfoliosForSort(a.portfolio, b.portfolio));
      return parsed;
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios/summary/fund/:id` }),
  },
);

/**
 * Fetch a specific position associated with a Fund
 */
export const GetAccurateFundPosition = new RequestHandler(
  'GetAccurateFundPosition',
  fetch =>
    async (fundId: UUID, portfolioId: UUID): Promise<EntityPosition> => {
      const res = await fetch(`/v1/portfolios/${portfolioId}/fund/${fundId}`);
      return entityPositionSchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/portfolios/:portfolioId/fund/:fundId` }),
  },
);

/**
 * Fetch all funds for the admin dashboard
 */
export const GetAdminFunds = new RequestHandler(
  'GetAdminFunds',
  fetch =>
    async (count?: number, offset?: number, name?: string): Promise<Array<AdminFund>> => {
      const res = await fetch('/v1/funds/all', {
        params: {
          count,
          offset,
          name,
        },
      });
      return z.array(adminFundSchema).parse(res);
    },
);

const allImpactFundDistributionRoundsSchema = z.object({
  current: uuidSchema,
  rounds: arraySchemaInvalidsFiltered(fundDistributionRoundSchema),
});
type AllImpactFundDistributionRounds = z.infer<typeof allImpactFundDistributionRoundsSchema>;
export const GetAllImpactFundDistributionRounds = new RequestHandler(
  'GetAllImpactFundDistributionRounds',
  fetch =>
    async (id: UUID): Promise<AllImpactFundDistributionRounds> => {
      const res = await fetch(`/v1/funds/${id}/distribution-rounds`);
      return allImpactFundDistributionRoundsSchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/:id/distribution-rounds` }),
  },
);

export type RoundIdInput = UUID | 'current';

export const GetImpactFundDistributionRoundSummary = new RequestHandler(
  'GetImpactFundDistributionRoundSummary',
  fetch =>
    async (id: UUID, roundId: RoundIdInput): Promise<FundDistributionRoundSummary> => {
      const res = await fetch(`/v1/funds/${id}/distribution-rounds/${roundId}/breakdown`, {
        params: {
          categoryCount: 5,
        },
      });
      return fundDistributionRoundSummarySchema.parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/:id/distribution-rounds/:roundId/breakdown` }),
  },
);

export const SearchImpactFundDistributionRecipients = new RequestHandler(
  'SearchImpactFundDistributionRecipients',
  fetch =>
    async (
      id: UUID,
      roundId: RoundIdInput,
      params?: {
        searchTerm?: string;
        nteeMajorCode?: Letter;
        count?: number;
        offset?: number;
      },
    ): Promise<Array<FundDistributionRecipient>> => {
      const res = await fetch(`/v1/funds/${id}/distribution-rounds/${roundId}/recipients`, {
        params,
      });
      return z.array(fundDistributionRecipientSchema).parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({
      default: `${baseURL}/v1/funds/:id/distribution-rounds/:roundId/recipients`,
    }),
  },
);

export const GetFundCollaborators = new RequestHandler(
  'GetFundCollaborators',
  fetch =>
    async (id: UUID): Promise<Array<FundCollaborator>> => {
      const res = await fetch(`/v1/funds/${id}/collaborators`);
      return z.array(fundCollaboratorSchema).parse(res);
    },
  {
    makeMockEndpoints: ({ baseURL }) => ({ default: `${baseURL}/v1/funds/:id/collaborators` }),
  },
);
