import { Flex, VStack } from '@chakra-ui/react';
import Link from 'next/link';

import { routes } from '@endaoment-frontend/routes';
import { Input } from '@endaoment-frontend/ui/forms';
import { ArrowIcon, LogoIcon } from '@endaoment-frontend/ui/icons';
import { Button, Card } from '@endaoment-frontend/ui/shared';

import styles from './ForNonprofitsFeatureSection.module.scss';

export const ForNonprofitsFeatureSection = () => {
  return (
    <section id='for-nonprofits-section' className={styles['for-nonprofits-feature-section']}>
      <VStack className={styles['for-nonprofits-feature-section__inner']} alignItems='flex-start'>
        <Button
          as={Link}
          href={routes.marketing.orgs()}
          variation='org'
          faded
          float={false}
          className={styles['for-nonprofits-feature-section__title']}>
          <LogoIcon color='currentColor' />
          <h2>For Nonprofits</h2>
          <ArrowIcon color='currentColor' width={28} />
        </Button>
        <h2 id='for-nonprofts-feature-title'>
          Complex gifts for <b>every nonprofit</b>
        </h2>
        <h4 id='for-nonprofts-feature-tagline'>Accept all kinds of donations without lifting a finger.</h4>
        <Flex className={styles['for-nonprofits-feature-section__grid']} gap='1.5rem'>
          <VStack alignItems='flex-start'>
            <h6>Seamless donations of nearly any asset</h6>
            <p>
              Process donations of crypto, stock, cash and more at no cost to your organization with our public profile
              pages for every 501(c)(3).*{' '}
            </p>
          </VStack>
          <VStack alignItems='flex-start'>
            <h6>Eliminate accounting and custodial overhead</h6>
            <p>
              Donated dollars are wired directly to a US bank account or digital wallet for free. We’ll handle tax
              receipts, liquidation and accounting.
            </p>
          </VStack>
          <VStack alignItems='flex-start'>
            <h6>Leverage novel fundraising tools</h6>
            <p>
              Through the <Link href={routes.app.fund({ vanityUrl: 'universal' })}>Universal Impact Pool</Link>, every
              donation is amplified. Fundraise on Endaoment, and your organization will receive a quarterly bonus.
            </p>
          </VStack>
        </Flex>
        <div className={styles['for-nonprofits-feature-section__actions']}>
          <Button variation='gray' as='a' href='mailto:partnerships@endaoment.org' filled>
            Talk with Us
          </Button>
          <Button variation='org' as={Link} href={routes.app.explore()} filled>
            Claim a Nonprofit
          </Button>
        </div>
        <VStack>
          <Flex
            as={Card}
            className={styles['for-nonprofits-feature-section__lookup']}
            alignItems='stretch'
            gap='1.5rem'>
            <VStack justifyContent='center' alignItems='flex-start' gap='0.25rem'>
              <h5>Look up an organization</h5>
              <p>Claim any U.S. 501(c)(3) organization to receive funds*</p>
            </VStack>
            <VStack
              as='form'
              method='get'
              action={routes.app.explore()}
              alignItems='flex-start'
              justifyContent='center'>
              <label>Search by EIN or Name</label>
              <Input
                name='searchTerm'
                placeholder='12-345678'
                rightElements={
                  <Button type='submit' variation='org' filled size='small' float={false}>
                    Lookup
                  </Button>
                }
              />
            </VStack>
          </Flex>
          <p className={styles['for-nonprofits-feature-section__disclaimer']}>
            *Provided the organizaton is in good standing with the IRS and meets Endaoment’s{' '}
            <Link href={routes.docs.orgFundingPolicy()}>Organization Funding Policy</Link>
          </p>
        </VStack>
      </VStack>
    </section>
  );
};
