import clsx from 'clsx';
import Link from 'next/link';

import { routes } from '@endaoment-frontend/routes';
import { NewFundIcon } from '@endaoment-frontend/ui/icons';
import { ActionButton } from '@endaoment-frontend/ui/shared';

import styles from './GetStartedButton.module.scss';

export const GetStartedButton = ({
  subtext,
  className,
  smaller = false,
}: {
  subtext?: string;
  className?: string;
  smaller?: boolean;
}) => {
  return (
    <ActionButton
      as={Link}
      color='fund'
      // TODO: When capture page is implemented we will direct them there
      href={routes.app.dashboard()}
      id='hero-action-button'
      text='Get Started'
      subtext={subtext ?? 'Sign in to open a donor-advised fund'}
      className={clsx(styles['get-started-button'], className, smaller && styles['get-started-button--smaller'])}
      spinny>
      <span>
        <NewFundIcon color='currentColor' fillOpacity={1} width={32} />
      </span>
    </ActionButton>
  );
};
