import { VStack } from '@chakra-ui/react';
import Image from 'next/image';

import { FloatingVideo } from '@endaoment-frontend/ui/shared';

import { GetStartedButton } from '../../components/GetStartedButton';
import buildingIcon from '../../images/decoration/building.png';
import robbiIcon from '../../images/decoration/robbi.png';

import styles from './UpgradeSection.module.scss';

export const UpgradeSection = () => {
  return (
    <section className={styles['upgrade-section']}>
      <VStack className={styles['upgrade-section__content']} alignItems='center'>
        <h2 className={styles['upgrade-section__title']}>
          Upgrade Your <b>Charitable Giving</b>
        </h2>
        <GetStartedButton className={styles['upgrade-section__button']} smaller />
      </VStack>
      <div className={styles['upgrade-section__decoration']}>
        <Image src={robbiIcon} alt='Robbi Icon' />
        <Image src={buildingIcon} alt='Building Icon' />
        {/* TODO: Replace videos (good for initial release) */}
        <FloatingVideo src='/videos/create-fund.mp4' />
        <FloatingVideo src='/videos/explore.mp4' />
      </div>
    </section>
  );
};
